const Stepper = ({items, current}) => {

    const itemStructure = items.reduce((object, item, index) => {
        if (!object.hasOwnProperty(item.category)) {
            object[item.category] = {};
        }

        if (!object[item.category].hasOwnProperty(item.orderNumber)) {
            object[item.category][item.orderNumber] = [];
        }

        object[item.category][item.orderNumber].push(item);

        return object;
    }, {});

    const totalCnt = Object.keys(itemStructure).reduce((acc, group) => {
        return acc + Object.keys(itemStructure[group]).length;
    }, 0);

    let currentItemIndex = 0;
    let currentIndx = 0;
    const groupNames = Object.keys(itemStructure);
    for (let i = 0; i < groupNames.length; i++) {
        const orderNumbers = Object.keys(itemStructure[groupNames[i]]);
        for (let i = 0; i < orderNumbers.length; i++) {

            if (Number(orderNumbers[i]) === current.orderNumber) {
                currentItemIndex = currentIndx;
            }
            currentIndx++;
        }
    }

    const categories = {}
    let lastIndex = -1;
    let firstIndex = -1;
    for (const groupName in itemStructure) {
        firstIndex = lastIndex + 1;
        lastIndex = lastIndex + Object.keys(itemStructure[groupName]).length;

        if (currentItemIndex < firstIndex) {
            categories[groupName] = null;
        } else if (currentItemIndex > lastIndex) {
            categories[groupName] = 100;
        } else {
            categories[groupName] = Math.round((currentItemIndex - firstIndex) / Object.keys(itemStructure[groupName]).length * 100);
        }
    }

    return (
        <div className={'survey-categories stepper'}>
            <div className={'items'}>
                {Object.keys(itemStructure).map((groupName, index) => {
                    const items = itemStructure[groupName];
                    const itemsCnt = Object.keys(items).length;
                    return <div key={index} className={'item'} style={{width: (itemsCnt / totalCnt * 100) + '%'}}>
                        {categories[groupName] !== null && categories[groupName] !== 100 &&
                        <div className={'category-name'}>{groupName}</div>}
                        <div className={'highlight'} style={{width: categories[groupName] + '%'}}></div>
                    </div>
                })}
            </div>
            <div
                className={'stepper-name'}>{currentItemIndex > 0 ? `${(currentItemIndex / totalCnt * 100).toFixed(0)}%` : '0%'}</div>
        </div>
    );
}
export default Stepper;
