import {CheckboxGroup, RadioGroup} from "../Controls/index";

const QuestionControl = ({data, onChange, form}) => {
    const name = data.index;
    const value = name in form ? form[name] : null;
    return <div className={'question-control'}>
        {data.inputType === 'radio' &&
        <RadioGroup
            options={data.options}
            name={name}
            value={value}
            onChange={onChange}
        />}
        {data.inputType === 'checkbox' &&
        <CheckboxGroup
            options={data.options}
            name={name}
            value={value}
        />}
    </div>
}

export default QuestionControl
