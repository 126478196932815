const RadioGroup = ({ options, onChange, name, value }) => {
    return <>
        {options.map((option, index) =>
            <label key={name + '-' + index} className={'control-radio'}>
                <input
                    id={'radio-item-' + name + '-' + index}
                    name={name}
                    value={option.value}
                    type="radio"
                    onChange={(ev) => onChange(ev.target.name, ev.target.value)}
                    defaultChecked={value === option.value}
                />
                <div className={'circle'}></div>
                <span htmlFor={'radio-item-' + name + '-' + index}>{option.label}</span>
            </label>
        )}
    </>;
}

export default RadioGroup;
