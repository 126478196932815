import axios from "axios";

const APIService = {
    getQuestionnaireByIdent: (ident) => {
        return axios.get(`/api/questionnaires/?ident=${ident}`);
    },
    getQuestionnaireItems: (questionaireId) => {
        return Promise.all([
            axios.get(`/api/questionnaires/${questionaireId}/items/`),
            [{
                generalType: 'custom',
                name: 'finish',
                type: 'finish'
            }]
        ])
    },
    saveQuestionnaireResult: (questionaireId, values) => {
        return axios.post(
            `/api/questionnaires/${questionaireId}/results/`,
            {
                answers: values
            }
        );
    },
    saveQuestionnaireContact: (questionaireId, resultId, values) => {
        return axios.post(
            `/api/questionnaires/${questionaireId}/results/${resultId}/contacts/`,
            {
                patient: values
            }
        );
    }
};

export default APIService;
