import './Survey.css';
import {useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import ResponsePage from "./scenes/ResponsePage";
import {useHistory, useParams} from "react-router-dom";
import SurveyPageQuestion from "./SurveyPageQuestion";
import FinishScreen from "../FinishScreen";
import ContactForm from "../ContactForm";
import APIService from "../../../service/APIService";

const Survey = ({
                    questionnaire,
                    scenes,
                    form,
                    setForm,
                    setContactsValue,
                    contactsValue,
                }) => {
    const history = useHistory();

    let {index} = useParams();
    index = index ? +index : 0;

    const [currentItem] = useState(scenes[index]);

    useEffect(() => {
        const onPopState = (event) => {
            const state = event.state.state;
            const currentIndex = state ? state.index : 0;

            setForm((prevForm) => {
                const newForm = {...prevForm};

                for (let i = currentIndex + 1; i < scenes.length; i++) {
                    delete newForm[scenes[i].index];
                }

                return newForm;
            });
        };

        window.addEventListener('popstate', onPopState);

        return () => {
            window.removeEventListener('popstate', onPopState);
        };
    }, []);

    const forceNextItem = useCallback((nextItemIndex = null) => {
        closeModal();
        if (nextItemIndex === null) {
            nextItemIndex = scenes.findIndex((item) => item.type === 'finish');
        }

        if (nextItemIndex !== null) {
            history.push('/survey/' + nextItemIndex, {index: nextItemIndex});
        }
    }, [history, scenes, currentItem]);

    const nextItem = useCallback(() => {
        let promise = new Promise((resolve, reject) => {
            const name = currentItem.index;
            if (!(name in form) && currentItem.type === 'question') {
                openModal();
            } else {
                resolve(form[name]);
            }
        });

        promise.then((value) => {
            let currentValue = null;
            let questionNextItem = null;
            if (currentItem.options) {
                currentValue = currentItem.options.find((item) => item.value === value);
                questionNextItem = scenes.findIndex((item) => item.index === currentValue.idNextItem);
            }

            const nextItemIndex = currentItem && currentItem.idNextItem
                ? scenes.findIndex((item) => item.index === currentItem.idNextItem)
                : null;

            forceNextItem(currentItem.type === 'question' ? questionNextItem : nextItemIndex);
        });
    }, [currentItem, form, scenes, forceNextItem]);

    const prevItem = useCallback(() => {
        history.goBack();
    }, [history]);

    const isFirst = scenes.indexOf(currentItem) === 0
    const isLast = scenes.indexOf(currentItem) === scenes.length - 1;

    const setFormValues = useCallback((name, value) => {
        let currentValue = null;
        let questionNextItem = null;
        if (currentItem.options) {
            currentValue = currentItem.options.find((item) => item.value === value);
            questionNextItem = scenes.findIndex((item) => item.index === currentValue.idNextItem);
        }

        setForm((item) => ({
            ...item,
            [name]: value
        }))

        if (currentValue.annotation) {
            return null;
        }

        const nextItemIndex = currentValue.idNextItem
            ? scenes.findIndex((item) => item.index === currentValue.idNextItem)
            : null;

        forceNextItem(currentItem.type === 'question' ? questionNextItem : nextItemIndex, ({
            ...form,
            [name]: value
        }));
    }, [currentItem.options, currentItem.type, setForm, scenes, forceNextItem, form]);

    const continueItem = useCallback(() => {
        forceNextItem(currentItem && currentItem.idNextItem
            ? scenes.findIndex((item) => item.index === currentItem.idNextItem)
            : null)
    }, [forceNextItem, currentItem, scenes]);

    useEffect(() => {
        if (questionnaire && currentItem.type === 'finish') {
            const formConvertedToArray = Object.entries(form).map((entry) => ({
                idQuestion: entry[0],
                answer: entry[1]
            }));
            APIService.saveQuestionnaireResult(questionnaire.id, formConvertedToArray)
                .then((res) => {
                    if (contactsValue) {
                        const resultId = res.data.result.id;
                        APIService.saveQuestionnaireContact(questionnaire.id, resultId, contactsValue);
                    }
                });
        }
    }, [currentItem.type, form, contactsValue])

    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        questionnaire && <>

            {currentItem.type === 'question' &&
            <SurveyPageQuestion
                nextItem={nextItem}
                prevItem={prevItem}
                questionnaire={questionnaire}
                scenes={scenes}
                currentItem={currentItem}
                form={form}
                setForm={setFormValues}
                isFirst={isFirst}
                isLast={isLast}
            />
            }

            {currentItem.type === 'response' &&
            <ResponsePage
                index={currentItem.name}
                currentItem={currentItem}
                nextItem={nextItem}
                prevItem={prevItem}
            />
            }


            {currentItem.type === 'finish' &&
            <FinishScreen currentItem={currentItem}/>
            }

            {currentItem.type === 'contact_form' &&
            <ContactForm
                nextItem={nextItem}
                prevItem={prevItem}
                setContactsValue={setContactsValue}
            />
            }

            <Modal
                isOpen={modalIsOpen}
                className={'proceed-modal'}
                overlayClassName={'proceed-overlay-modal'}
            >
                <h2>Are you sure you want to proceed?<br/>
                    The question was not completed.</h2>

                <div className={'buttons'}>
                    <div>
                        <button className={'btn btn-link'}
                                onClick={continueItem}>Proceed anyway
                        </button>
                    </div>
                    <div>
                        <button className={'btn btn-primary'} onClick={closeModal}>Back to the question</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Survey;
