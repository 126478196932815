import {useForm} from 'react-hook-form';
import './ContactForm.css';

const ContactForm = ({prevItem, nextItem, setContactsValue}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();

    const handleSendContacts = (values) => {
        setContactsValue(values);
        nextItem();
    };

    return (
        <div className={'page-contactForm'}>
            <div className={'page-contactForm-wrapper'}>
                <div className={'previous-container'}>
                    <div className={'btn btn-link'} onClick={prevItem}>
                        <i className="fas fa-arrow-left"></i>
                        &nbsp;&nbsp;
                        Go back
                    </div>
                </div>

                <div className={'page-header'}>
                    {'Leave your contact details'}
                </div>

                <div className={'texts'}>
                    <form onSubmit={handleSubmit(handleSendContacts)}>

                        <div className={'input-wrapper'}>
                            <div className={'label'}>FIRST NAME</div>
                            <input type="text"
                                placeholder={'First Name'}
                                className={'form-control'}
                                {...register("first_name", {required: true})}
                            />
                            {errors.first_name && <span className={'form-error'}>This field is required</span>}
                        </div>
                        <div className={'input-wrapper'}>
                            <div className={'label'}>LAST NAME</div>
                            <input type="text"
                                placeholder={'Last Name'}
                                className={'form-control'}
                                {...register("last_name", {required: true})}
                            />
                            {errors.last_name && <span className={'form-error'}>This field is required</span>}
                        </div>
                        <div className={'input-wrapper'}>
                            <div className={'label'}>EMAIL</div>
                            <input type="text"
                                placeholder={'Email'}
                                className={'form-control'}
                                {...register("email", {required: true, pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format"
                                    }})}
                            />
                            {errors.email && errors.email.type === 'required' && <span className={'form-error'}>This field is required</span>}
                            {errors.email && errors.email.type === 'pattern' && <span className={'form-error'}>{errors.email.message}</span>}
                        </div>
                        <div className={'input-wrapper'}>
                            <div className={'label'}>PHONE NUMBER</div>
                            <input type="tel"
                                placeholder={'Phone Number'}
                                className={'form-control'}
                                {...register("phone", {required: true})}
                            />
                            {errors.phone && <span className={'form-error'}>This field is required</span>}
                        </div>
                        <div className={'button-wrapper'}>
                            <button type={'submit'} className={'btn btn-primary'}>
                                Send Contacts
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default ContactForm;
