import { Link } from 'react-router-dom';
import logo from "../assets/hydra-logo.png";

const Header = ({clearForm}) => {
    return <div className={"header-container"}>
        <div className={"logo-container"}>
            <Link to={'/'} onClick={clearForm}>
                <img src={logo} alt={'Hydra logo'} />
            </Link>
        </div>
    </div>
}

export default Header;
