import './WelcomeScreen.css';
import { useEffect, useRef, useState } from 'react';
import {Link} from "react-router-dom";

const WelcomeScreen = () => {

    const [refs] = useState([
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ])

    useEffect(() => {

        const timeout = [];
        refs.forEach((element, index) => {
            timeout.push(
                setTimeout(() => {
                    element.current.className += ' fade-in';
                }, (index === 0 ? 1000 : 2000) * (index + 1))
            );
        });

        return () => {
            timeout.forEach((item) => {
                clearTimeout(item);
            })
        }
    }, [refs]);

    return (
        <div className={'page-welcomeScreen '}>
            <h2 className={'page-header'}>Hi, we are Hydra!</h2>

            <div className={'phrases'}>
                <div className={'phrase phrase1'} ref={refs[0]}>
                    Hydra helps people like you problem-solve health conditions and explore treatment options.
                </div>
                <div className={'phrase phrase2'} ref={refs[1]}>
                    To do so, we first use technology built on real patient data and treatment algorithms to help you find answers to your health questions.
                </div>
                <div className={'phrase phrase3'} ref={refs[2]}>
                    Next, we guide you to the most optimal treatment path and
                    top-doctors via concierge medical coordination services.
                </div>
                <div className={'phrase phrase4'} ref={refs[3]}>
                    The end result is a more informed, happier and healthier you.
                </div>
                <div className={'phrase phrase5'} ref={refs[4]}>
                    So what do you say? Let’s get started!
                </div>
            </div>

            <div className={'content-welcomeScreen hydra-animation'}>
                <Link to={'/begin-survey'} className={'btn btn-begin-survey btn-primary'}>
                    Begin Survey
                </Link>
            </div>
        </div>
    );
}

export default WelcomeScreen;
