const scenesVO = {
    question: (item) => {

        const inputs = {
            'single_answer': 'radio'
        };

        let answers = item.values.answers || [];

        answers = answers.map((item) => {
            let annotation = null;
            if (item.annotation) {
                annotation = {
                    header: item.annotation.caption,
                    body: item.annotation.text
                }
            }

            return {
                label: item.value,
                value: item.value,
                idNextItem: item.idNextItem,
                annotation
            }
        });

        return ({
            index: item.id,
            idNextItem: item.idNextItem,
            type: item.generalType,
            question: item.caption,
            category: item.categoryCaption,
            inputType: inputs[item.typeIdent],
            orderNumber: item.orderNumber,
            options: answers
        });
    },
    info_block: (item) => {
        return {
            index: item.id,
            idNextItem: item.idNextItem,
            type: 'response',
            name: item.typeIdent,
            values: item.values
        }
    },
    contact_form: (item) => {
        return {
            index: item.id,
            type: item.generalType,
            name: item.typeIdent,
            idNextItem: item.idNextItem,
            values: item.values
        }
    },
    custom: (item) => {
        return {
            ...item
        }
    }
}

export default scenesVO;
