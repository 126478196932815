const Footer = () => {
    return <div className={"footer-container"}>
        <div className={"copyright"}>
            © 2021 Hydra Health Tech. All right reserved.
        </div>
        <div className={"footer-menu"}>
            <div className={"footer-menu-item"}>
                <a href={"/privacy-policy"}>Privacy Policy</a>
            </div>
            <div className={"footer-menu-item"}>
                <a href={"/terms-of-service"}>Terms of Service</a>
            </div>
            <div className={"footer-menu-item"}>
                <a href={"/contact-support"}>Contact Support</a>
            </div>
        </div>
    </div>
}

export default Footer;
