import './ResponsePage.css';
import ArcProgress from 'react-arc-progress';
import {useEffect, useRef, useState} from "react";

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Bar, Doughnut, Line} from 'react-chartjs-2';

ChartJS.register(
    BarElement,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Response1Container = ({currentItem}) => {
    let size = 200;

    let textStyle = {
        size: '60',
        color: '#A8BAFF',
        font: 'Gilroy',
        x: 85,
        y: 100,
    };

    let customText = {
        text: '%',
        x: 135,
        y: 100,
        size: '55',
        font: "'Gilroy'",
        color: '#A8BAFF',
    };

    if (Math.min(window.screen.width, window.screen.height) < 450) {
        size = 100;

        textStyle.size = '20';
        textStyle.x = 45;
        textStyle.y = 50;

        customText.x = 65;
        customText.y = 50;
        customText.size = '20';
    }

    return <>
        <div className={'page-header'}>
            Did you know...
        </div>
        <div className={'stat-container'}>

            {currentItem.values.charts.map((item, index) =>
                <div className={'stat-item'} key={index}>
                    <ArcProgress
                        progress={item.value / 100}
                        text={item.value.toString()}
                        textStyle={textStyle}
                        customText={[customText]}
                        fillColor='#A8BAFF'
                        speed={-70}
                        size={size}
                    />

                    <div>
                        {item.label}
                    </div>
                </div>
            )}
        </div>

        <div className={'phrases'}>
            <div className={'phrase phrase1'}>
                {currentItem.values.message}
            </div>
        </div>
    </>;
};
const Response2Container = ({currentItem}) => {
    const [refs] = useState([
        useRef(null),
        useRef(null),
        useRef(null),
    ])

    useEffect(() => {

        const timeout = [];
        refs.forEach((element, index) => {
            timeout.push(
                setTimeout(() => {
                    element.current.className += ' fade-in';
                }, (index === 0 ? 1000 : 2000) * (index + 1))
            );
        });

        return () => {
            timeout.forEach((item) => {
                clearTimeout(item);
            })
        }
    }, [refs]);

    const messages = currentItem.values.messages;

    return <>
        <div className={'phrases trigger'}>
            {messages[0] && <div className={'phrase phrase2'} ref={refs[0]}>
                {messages[0]}
            </div>}
            {messages[1] && <div className={'phrase phrase3'} ref={refs[1]}>
                {messages[1]}
            </div>}
            {messages[2] && <div className={'phrase phrase4'} ref={refs[2]}>
                {messages[2]}
            </div>}
        </div>
    </>
};
const Response3Container = ({currentItem}) => {
    const [refs] = useState([
        useRef(null),
        useRef(null),
    ])

    useEffect(() => {

        const timeout = [];
        refs.forEach((element, index) => {
            timeout.push(
                setTimeout(() => {
                    element.current.className += ' fade-in';
                }, (index === 0 ? 1000 : 2000) * (index + 1))
            );
        });

        return () => {
            timeout.forEach((item) => {
                clearTimeout(item);
            })
        }
    }, [refs]);

    const messages = currentItem.values.messages;

    return <>
        <div className={'hydra-animation-response3'}></div>
        <div className={'phrases trigger'}>
            {messages[0] && <div className={'phrase phrase5'} ref={refs[0]}>
                {messages[0]}
            </div>}
            {messages[1] && <div className={'phrase phrase6'} ref={refs[1]}>
                {messages[1]}
            </div>}
        </div>
    </>
};
const Response4Container = ({currentItem}) => {
    const [refs] = useState([
        useRef(null),
        useRef(null),
    ])

    useEffect(() => {

        const timeout = [];
        refs.forEach((element, index) => {
            timeout.push(
                setTimeout(() => {
                    element.current.className += ' fade-in';
                }, (index === 0 ? 1000 : 2000) * (index + 1))
            );
        });

        return () => {
            timeout.forEach((item) => {
                clearTimeout(item);
            })
        }
    }, [refs]);

    const messages = currentItem.values.messages;

    return <>
        <div className={'phrases trigger'}>
            {messages[0] && <div className={'phrase phrase7'} ref={refs[0]}>
                {messages[0]}
            </div>}
            {messages[1] && <div className={'phrase phrase8'} ref={refs[1]}>
                {messages[1]}
            </div>}
        </div>
    </>
};

const ResponsePieChartContainer = ({currentItem}) => {
    const data = {
        labels: Object.keys(currentItem.values.data),
        datasets: [
            {
                data: Object.values(currentItem.values.data).map((item) => item.replace('%', '')),
                backgroundColor: [
                    '#9C78E7',
                    '#784A96',
                    '#A8BAFF',
                    '#A8BAFF4D',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    '#9C78E7',
                    '#784A96',
                    '#A8BAFF',
                    '#A8BAFF4D',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        responsive: true
    }
    return <>
        <div className={'page-header'}>
            {currentItem.values.caption}
        </div>

        <div className={''}>
            <Doughnut data={data} options={options}/>
        </div>
    </>
};

const ResponseLineChartContainer = ({currentItem}) => {

    if (!currentItem.values.caption) {
        return <div>not enough data</div>
    }

    const colors = ['rgba(168, 186, 255, 0.5)', '#EAEAEA'];
    const data = {
        labels: Object.keys(currentItem.values.lines[0]),
        datasets: currentItem.values.lines.map((val) => {
            const color = colors.pop();
            return {
                label: 'Dataset 1',
                data: Object.values(val),
                borderColor: color,
                backgroundColor: color
            }
        })
    };
    const options = {
        responsice: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };
    return <>
        <div className={'page-header'}>
            {currentItem.values.caption}
        </div>
        <div className={'page-description'}>
            {currentItem.values.description}
        </div>
        <div className={''}>
            <Line options={options} data={data}/>
        </div>
    </>
};

const ResponseColumnChartContainer = ({currentItem, vertical}) => {
    const data = {
        labels: Object.keys(currentItem.values.data),
        datasets: [
            {
                label: 'Dataset 1',
                data: Object.values(currentItem.values.data).map((item) => item.replace('%', '')),
                backgroundColor: '#A8BAFF80',
            },
        ],
    };
    const options = {
        indexAxis: vertical ? 'x' : 'y',
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };
    return <>
        <div className={'page-header'}>
            {currentItem.values.caption}
        </div>
        <div className={'page-description'}>
            {currentItem.values.description}
        </div>
        <div className={''}>
            <Bar options={options} data={data}/>
        </div>
    </>
};

const ResponsePage = ({index, currentItem, prevItem, nextItem}) => {

    let pageContent = null;
    switch (index) {
        case 'charts': {
            pageContent = <Response1Container currentItem={currentItem}/>;
        }
            break;
        case 'dialog_2': {
            pageContent = <Response2Container currentItem={currentItem}/>;
        }
            break;
        case 'dialog_3': {
            pageContent = <Response3Container currentItem={currentItem}/>;
        }
            break;
        case 'dialog_1': {
            pageContent = <Response4Container currentItem={currentItem}/>;
        }
            break;
        case 'pie_chart_1':
        case 'pie_chart_2': {
            pageContent = <ResponsePieChartContainer currentItem={currentItem}/>
        }
            break;
        case 'line_chart': {
            pageContent = <ResponseLineChartContainer currentItem={currentItem}/>
        }
            break;
        case 'column_chart': {
            pageContent = <ResponseColumnChartContainer currentItem={currentItem} vertical={true}/>
        }
            break;
        case 'bar_chart': {
            pageContent = <ResponseColumnChartContainer currentItem={currentItem} vertical={false}/>
        }
            break;
        case 'map':
        default: {
            pageContent = index
        }
    }

    return <div className={'page-survey-response page-survey-response-' + index}>

        <div className={'previous-container'}>
            <div className={'btn btn-link'} onClick={prevItem}>
                <i className="fas fa-arrow-left"></i>
                &nbsp;&nbsp;
                Go back
            </div>
        </div>

        {pageContent}

        <div className={'buttons'}>
            <button className={'btn btn-primary btn-continue'} onClick={nextItem}>Continue</button>
        </div>
    </div>;
};

export default ResponsePage;
