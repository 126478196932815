import './FinishScreen.css';
import youtubeIcon from '../../../assets/youtube.png';
import textIcon from '../../../assets/file-text.png';

const FinishScreen = () => {
    return (
        <>
            <div className={'page-finishScreen'}>
                <div className={'finish-top-container'}>
                    <div className={'hydra-image'}></div>
                    <div className={'texts'}>
                        <p className={'text-style-1'}>We know it’s challenging to live with pain and uncomfortable
                            symptoms.
                            Fortunately, we know what steps you should take to help you find relief.</p>
                        <p className={'text-style-2'}>Our office will call you to make a consultation</p>
                    </div>
                </div>
                <div className={'finish-bottom-container'}>
                    <div className={'texts'}>
                        Check some additional information about Pelvic Congestion Syndrome:
                    </div>

                    <div className={'text-style-2'}>
                        <div className={'feedback-item'}>
                            <div className={'icon'}>
                                <img src={youtubeIcon} alt={'Why not to ignore Pelvic Congestion Syndrome symptoms?'}/>
                            </div>
                            <div className={'text'}>
                                <p>Why not to ignore Pelvic Congestion Syndrome symptoms?</p>
                            </div>
                        </div>

                        <div className={'feedback-item'}>
                            <div className={'icon'}>
                                <img src={textIcon} alt={'Article title about PCS'}/>
                            </div>
                            <div className={'text'}>
                                <p>Article title about PCS</p>
                            </div>
                        </div>

                        <div className={'feedback-item'}>
                            <div className={'icon'}>
                                <img src={textIcon} alt={'Pelvic Congestion Syndrome danger'}/>
                            </div>
                            <div className={'text'}>
                                <p>Pelvic Congestion Syndrome danger</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    );
};

export default FinishScreen;
