import {useCallback, useMemo} from "react";
import Stepper from "./Stepper";
import QuestionControl from "./QuestionControl";

const SurveyPageQuestion = ({
                                questionnaire,
                                scenes,
                                form,
                                currentItem,
                                setForm,
                                prevItem,
                                nextItem,
                                isFirst,
                            }) => {

    const currentValueObj = useMemo(() => {
        let value = null;
        const name = currentItem.index;
        const valueString = name in form ? form[name] : null;
        if (name !== null && !!currentItem.options) {
            value = currentItem.options.find((opt) => {
                return opt.value === valueString
            });
        }

        return value;
    }, [form, currentItem]);

    const onChangeForm = useCallback((name, value) => {
        setForm(name, value)
    }, [setForm]);

    const questionItems = scenes.filter(({type}) => type === 'question');

    const hasAnnotation = currentValueObj && !currentValueObj.annotation

    return <div className={'page-survey'}>
        <h2 className={'survey-name'}>
            {questionnaire.caption}
        </h2>

        <Stepper items={questionItems} current={currentItem}/>

        <div className={'question-container'}>
            <h4 className={'question-name'}>{currentItem.question}</h4>

            <QuestionControl data={currentItem} onChange={onChangeForm} form={form}/>

            {(!currentValueObj || hasAnnotation) &&
            <div className={'buttons'}>
                <div>
                    {!isFirst && <div className={'btn btn-link'} onClick={prevItem}>Go back</div>}
                </div>
                <div>
                    <div className={'btn btn-link'} onClick={nextItem}>Continue</div>
                </div>
            </div>
            }

            {currentValueObj && currentValueObj.annotation &&
            <div className={'annotation'}>
                <div className={'annotation-icon'}></div>
                <div>
                    <div className={'annotation-header'}>{currentValueObj.annotation.header}</div>
                    <div className={'annotation-body'}>{currentValueObj.annotation.body}</div>
                </div>
                <div>
                    <div className={'btn btn-outline'} onClick={nextItem}>Continue</div>
                </div>
            </div>
            }
        </div>
    </div>;
}

export default SurveyPageQuestion;
