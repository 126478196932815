import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement('#root');

axios.interceptors.request.use(function (config) {
    config.baseURL = 'https://api.hydra.dmytrobondarenko.dev.clients.in.ua/';
    return config;
});

const [questionnaireIdent] = window.location.pathname.split('/').filter(word => word !== '');

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={'/' + questionnaireIdent}>
            <App questionnaireIdent={questionnaireIdent} />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
