import {useEffect, useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {Route, useLocation} from "react-router-dom";
import SlideRoutes from 'react-slide-routes';

import WelcomeScreen from './components/screens/WelcomeScreen/index';
import BeginSurvey from "./components/screens/BeginSurvey/index";
import Survey from "./components/screens/Survey";
import FinishScreen from "./components/screens/FinishScreen";
import scenesVO from "./vo";
import APIService from "./service/APIService";

function App({questionnaireIdent}) {
    const location = useLocation();

    const [questionnaire, setQuestionnaire] = useState(null);
    const [scenes, setScenes] = useState(null);
    const [form, setForm] = useState({});
    const [contactsValue, setContactsValue] = useState(null);

    const clearForm = () => setForm({});

    useEffect(() => {
        if (window.location.pathname !== '/' + questionnaireIdent) {
            window.location.href = '/' + questionnaireIdent;
        }
    }, []);

    useEffect(() => {
        APIService.getQuestionnaireByIdent(questionnaireIdent)
            .then(({data: {questionnaire}}) => {
                setQuestionnaire({
                    id: questionnaire.id,
                    caption: questionnaire.caption,
                });

                return APIService.getQuestionnaireItems(questionnaire.id);
            })
            .then(([{data: {items}}, additionalItems]) => {
                items = items.concat(additionalItems)

                setScenes(items.map((item) => scenesVO[item.generalType](item)));
            });
    }, []);

    return (
        scenes && <div className="body-container">
            <Header clearForm={clearForm} />
            <div className={"page-container"}>
                <SlideRoutes location={location}>
                    <Route path={'/'} exact component={WelcomeScreen}/>
                    <Route path={'/survey/:index'}
                           render={(routeProps) =>
                               <Survey
                                   {...routeProps}
                                   questionnaire={questionnaire}
                                   scenes={scenes}
                                   form={form}
                                   setForm={setForm}
                                   setContactsValue={setContactsValue}
                                   contactsValue={contactsValue}
                               />}
                    />
                    <Route path={'/survey'}
                           render={(routeProps) =>
                               <Survey
                                   {...routeProps}
                                   questionnaire={questionnaire}
                                   scenes={scenes}
                                   form={form}
                                   setForm={setForm}
                                   setContactsValue={setContactsValue}
                                   contactsValue={contactsValue}
                               />}
                    />
                    <Route path={'/begin-survey'} component={BeginSurvey}/>
                    <Route path={'/finish'} component={FinishScreen}/>
                </SlideRoutes>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
