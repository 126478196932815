const CheckboxGroup = ({ options, onChange, keyIndex }) => {
    return <>
        {options.map((option, index) =>
            <label key={keyIndex + '-' + index} className={'control-checkbox'}>
                <input
                    id={'radio-item-' + keyIndex + '-' + index}
                    name={keyIndex}
                    value={option.value}
                    type="checkbox"
                />
                <div className={'square'}></div>
                <span htmlFor={'radio-item-' + keyIndex + '-' + index}>{option.label}</span>
            </label>
        )}
    </>;
}

export default CheckboxGroup;
