import './BeginSurvey.css';
import {Link} from "react-router-dom";

const BeginSurvey = () => {
    return (
        <div className={'page-beginSurvey'}>
            <div className={'content-beginSurvey'}>
                <div className={'phrases'}>
                    <div className={'phrase phrase1'}>
                        Now I ask you to fill in a questionnaire to find out if you have any symptoms of Pelvic
                        Congestion
                        Syndrome.
                        I will be your assistant all the way!
                    </div>
                </div>
                <div className={'hydra-logo'}>

                </div>
                <div className={'buttons'}>
                    <Link to={'/survey'} className={'btn btn-primary'}>
                        Let's Start!
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default BeginSurvey;
